import { apiInstance } from './ApiInstance';

class DocenteService {
  static async paises() {
    try {
      const response = await apiInstance.get(`/pais`);
      if (response.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async nivelesAcademicos() {
    try {
      const response = await apiInstance.get(`/nivel-academico`);
      if (response.data) {
        if (response.data.transaccion) {
          return response.data.datos;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async profesiones() {
    try {
      const response = await apiInstance.get(`/profesion`);
      return response.data;
    } catch (error) {
      console.error("Docente.profesiones: ", error);
      return null;
    }
  }

  static async tiposDiscapacidades(){
    try {
        const response = await apiInstance.get(`/discapacidad`);
        if(response.data){  
            return response.data;
        }else{
            return null;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

  static async departamentos() {
    try {
      const response = await apiInstance.get(`/departamento`);
      if (response.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async municipios(departamentoId) {
    try {
      const response = await apiInstance.get(`/municipio/${departamentoId}`);
      if (response.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async estadosCivil() {
    try {
      const response = await apiInstance.get(`/estado-civil`);
      if (response.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async universidades() {
    try {
      const response = await apiInstance.get(`/universidad`);
      if (response.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static solicitarRecuperacion(data){
    return apiInstance.post('/docente/recuperar/password', data);
}
}

export { DocenteService };
