import * as yup from 'yup';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const validateApellidos = function (value, ctx) {
  const otherField = ctx.path === 'apellidoPaterno' ? 'apellidoMaterno' : 'apellidoPaterno';
  const otherValue = ctx.parent[otherField];
  if ((!value || value.trim() === '') && (!otherValue || otherValue.trim() === '')) {
    return false;
  }
  return true;
};

const getMinDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date;
};

export const datosSchema = yup.object().shape({
  documento: yup.string().min(6, 'Debe contener 6 caracteres como mínimo').required('El dato es obligatorio'),
  expedido: yup.mixed().nullable(false).required('El dato es obligatorio'),
  nombres: yup.string().required('El dato es obligatorio'),
  apellidoPaterno: yup.string().nullable().test({
    name: 'apellidoPaterno',
    message: 'Debe proporcionar al menos un apellido',
    test: validateApellidos
  }),
  apellidoMaterno: yup.string().nullable().test({
    name: 'apellidoMaterno',
    message: 'Debe proporcionar al menos un apellido',
    test: validateApellidos
  }),
  sexo: yup.mixed().nullable(false).required('El dato es obligatorio'),
  fechaNacimiento: yup.date().max(getMinDate(), 'Debe ser mayor de 18 años').required('El dato es obligatorio').typeError('Ingrese una fecha válida'),
  estadoCivil: yup.mixed().nullable(false).required('El dato es obligatorio'),

  paisOrigen: yup.mixed().nullable(false).required('El dato es obligatorio'),
  departamentoOrigen: yup.mixed().nullable(false).required('El dato es obligatorio'),
  paisResidencia: yup.mixed().nullable(false).required('El dato es obligatorio'),
  departamentoResidencia: yup.mixed().nullable(false).required('El dato es obligatorio'),
  municipioResidencia: yup.mixed().nullable(false).required('El dato es obligatorio'),

  direccion: yup.string().required('El dato es obligatorio'),
  celular: yup.number().typeError('El número de celular debe ser numérico').min(8, 'Debe contener 7 números como mínimo').required('El dato es obligatorio'),
  correo: yup.string().matches(emailRegex, 'Escriba un correo válido').required('El dato es obligatorio'),
  password: yup.string().when('sesionGoogle', {
    is: false,
    then: (schema) => schema.min(6, 'La contraseña debe contener 6 caracteres como mínimo').required('El campo contraseña es obligatorio'),
    otherwise: (schema) => schema.min(0),
  }),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Las contraseñas deben coincidir')
    .required('Por favor, repite la contraseña'),

  tieneDiscapacidad: yup.bool().required('El dato es obligatorio'),
  tipoDiscapacidad: yup
    .mixed()
    .nullable(true)
    .when('tieneDiscapacidad', {
      is: true,
      then: yup.string().required('El dato es obligatorio'),
    }),

  profesion: yup.mixed().nullable(false).required('El dato es obligatorio'),
  nivelAcademico: yup.mixed().nullable(false).required('El dato es obligatorio'),
  lugarTrabajo: yup.string().required('El dato es obligatorio'),
  telefonoOficina: yup.number().required('El dato es obligatorio'),
});
