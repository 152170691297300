import { createRouter, createWebHistory } from 'vue-router';
import { isAuthenticatedGuard } from './authGuard';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../modules/login/FormLogin.vue'),
    },
    {
        path: '/registro',
        name: 'registro-docente',
        component: () => import('../modules/registro/FormRegistro.vue'),
    },
    {
        path: '/',
        component: () => import('../components/Dashboard.vue'),
        beforeEnter: [isAuthenticatedGuard],
    },
    {
        path: '/curriculum',
        component: () => import('../modules/curriculum/Curriculum.vue'),
        beforeEnter: [isAuthenticatedGuard],
    },

    // Rutas de declaraciones juraadas
    {
        path: '/declaracion-jurada',
        component: () => import('../modules/declaracion-jurada/DeclaracionJurada.vue'),
        beforeEnter: [isAuthenticatedGuard],
    },
    {
        path: '/listas-estudiante',
        component: () => import('../modules/asignaturas/Listas.vue'),
        beforeEnter: [isAuthenticatedGuard],
    },
    {
        path: '/listas-estudiante/:asignaturaId/detalle/:grupo',
        component: () => import('../components/asignatura/DetalleListas.vue'),
        beforeEnter: [isAuthenticatedGuard],
    },
    {
        path: '/calificaciones-finales',
        component: () => import('../modules/asignaturas/CalificacionesFinales.vue'),
        beforeEnter: [isAuthenticatedGuard],
    },
    {
        path: '/calificaciones-finales/:asignaturaId/detalle/:grupo',
        component: () => import('../components/asignatura/DetalleCalificacionesFinales.vue'),
        beforeEnter: [isAuthenticatedGuard],
    },
    {
        path: '/calificaciones-nivelacion',
        component: () => import('../modules/asignaturas/CalificacionesNivelacion.vue'),
        beforeEnter: [isAuthenticatedGuard],
    },
    {
        path: '/calificaciones-nivelacion/:asignaturaId/detalle/:grupo',
        component: () => import('../components/asignatura/DetalleCalificacionesNivelacion.vue'),
        beforeEnter: [isAuthenticatedGuard],
    },
    {
        path: '/planillas-adicionales',
        component: () => import('../modules/asignaturas/PlanillasAdicionales.vue'),
        beforeEnter: [isAuthenticatedGuard],
    },
    {
        path: '/planillas-adicionales/:asignaturaId/detalle/:grupo',
        component: () => import('../components/asignatura/DetallePlanillasAdicionales.vue'),
        beforeEnter: [isAuthenticatedGuard],
    },
    {
        path: '/horarios',
        component: () => import('../modules/asignaturas/CalificacionesNivelacion.vue'),
        beforeEnter: [isAuthenticatedGuard],
    },
    {
        path: '/declaracion-jurada/editar/:id',
        component: () => import('../modules/declaracion-jurada/EditarDeclaracionJurada.vue'),
        beforeEnter: [isAuthenticatedGuard],
    },
    {
        path: '/carta-invitacion',
        component: () => import('../modules/carta-invitacion/Lista.vue'),
        beforeEnter: [isAuthenticatedGuard],
    },
    {
        path: '/contratos',
        component: () => import('../modules/contratos/Lista.vue'),
        beforeEnter: [isAuthenticatedGuard],
    },
    // Rutas Públicas

    // ruta para acceder al formulario de publico de inscripcion
    // {
    // 	path: '/inscripcion/programa/:id/:inscripcionId?',
    // 	name: 'inscripcion-publico',
    // 	component: () => import('../modules/inscripcion/FormInscripcionPublico.vue'),
    // },

    {
        path: '/:pathMatch(.*)*',
        redirect: { name: 'login' },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    },
});

export default router;
